<script>
    import {_, locale, number} from 'svelte-i18n';
    import Item from './Item.svelte';

    export let item;
    export let gameItem;

    export let itemDetails = {};

    function setItemDetails() {
        
        itemDetails.title = $_('PrisonCampLiberationPassPackItemTitle', {
            values: { AMOUNT: $number(gameItem.amount) },
        });

        itemDetails.description =  $_("PrisonCampLiberationPassPackItemDescription") ;
        
        itemDetails.amount = item.amount;
        itemDetails.icon = "prison-camp-release";

        itemDetails.rarity = gameItem.rarity;
        itemDetails.claimed = item.claimed;
    }


    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails} />
