<script>
    import {_, locale} from 'svelte-i18n';
    import Item from './Item.svelte';

    export let item;
    export let gameItem;

    export let itemDetails = {};

    function setItemDetails() {
        itemDetails.title =  $_("playerRenameItemName") ;
        itemDetails.description =  $_("playerRenameItemDescription") ;

        itemDetails.amount = item.amount;
        itemDetails.icon = "playerRenameItem";

        itemDetails.rarity = gameItem.rarity;
        itemDetails.claimed = item.claimed;
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails} />
