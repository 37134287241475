<script>
    import {_, locale, number} from 'svelte-i18n';
    import {createEventDispatcher} from 'svelte';
    import Ribbon from '../lib/Ribbon.svelte';
    import Starburst from '../lib/Starburst.svelte';
    import Countdown from '../lib/Countdown.svelte';
    import {gameConfig, selectedCurrency, user} from '../stores';
    import {getStorePackConfig} from '../yaml';
    import {Button} from 'svelte-chota';
    import {amazon_pack_image_url, collect_daily_subscription_route} from '../constants';
    import ItemsListing from './ItemsListing.svelte';
    import {getPackRarity, resourceDetails} from '../globals';

    export let subscription = {};
    export let storeSection = ''; // subscriptions or resources
    export let isPurchased = {};
    export let globals = {};

    let packDetails = null;
    const dispatch = createEventDispatcher();

    let processing = false;
    let animate = false;
    let rarity = subscription.rarity;
    let goldBonus = subscription.goldBonus || 0;
    let goldWorth = subscription.goldWorth || 0;
    let goldAmount = storeSection === 'subscriptions' ? isPurchased ? subscription.rewards.gold || 0 : subscription.dailyRewards.gold || 0
        : isPurchased ? subscription.rewards.gold || 0 : subscription.gold || 0;

    let days = isPurchased ? subscription.rewards.days : subscription.dailyRewards.days;

    let items = isPurchased
        ? subscription.rewards.items || []
        : subscription.dailyRewards.items || [];

    function formatTimeFriendly(seconds) {
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        let timeString = `${minutes} ${$_('Minute')}`;

        if (seconds - 1 > 60 * 60 * 24) {
            timeString = `${days} ${$_('Days')}`;
        } else if (seconds > 60 * 60) {
            timeString = `${hours} ${$_('Hours')}`;
        } else if (seconds >= 60) {
            timeString = `${minutes} ${$_('Minutes')}`;
        }

        return timeString;
    }

    globals.resourceDetails = resourceDetails;
    globals.formatTimeFriendly = formatTimeFriendly;
    globals.goldItemAmount = storeSection === 'subscriptions' ? days : 1;

    async function collectDailySubscription() {

        try {
            processing = true;
            const response = await fetch(collect_daily_subscription_route, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    packId: subscription.packId,
                }),
            });

            if (!response.ok) {
                const message = `An error has occured: ${response.status}`;
                throw new Error(message);
            }

            const res = await response.text();
            animate = true;
            // collectedAt = new Date();
            // remaining = Math.abs(getNextMidnightTime() - collectedAt);
            // endDate = new Date(new Date().getTime() + remaining);
            subscription.collected = true;
            // console.log(res)

        } catch (error) {
            console.error(error);
        } finally {
            processing = false;
        }
    }

    async function getSubscriptionPackDetails() {
        let details = {};

        await getUnityPackConfig().then((config) => {
            details.title = config['specialTitleKey'];
            details.description = config['specialTitleDescriptionKey'];

            if (storeSection === 'subscriptions') {
                details.showOneTimeOnly = config['showOneTimeOnly'];
                details.showTimer = config['showTimer'];
            }
        });

        return details;
    }

    function getUnityPackConfig() {
        if (subscription.bundleName !== undefined) {
            return getStorePackConfig(
                amazon_pack_image_url + '/' + subscription.bundleName + '/config.asset',
            );
        }
    }


    async function displayStoreSection() {
        await getSubscriptionPackDetails().then((data) => {
            packDetails = data;

            packDetails.title = $_(data.title);
            packDetails.subTitle = $_(data.title);

            if (storeSection === 'subscriptions') {
                packDetails.description = $_('SubscriptionDescription', {
                    values: {
                        DAYS: '<b>' + days + '</b>',
                        GOLD_VALUE1: '<b>' + $number(goldAmount * days) + '</b>',
                        GOLD_VALUE2: '<b>' + $number(goldWorth) + '</b>',
                    },
                });
            } else {
                packDetails.description = $_(data.description);
            }
        });
    }

    function buyPack() {
        dispatch('buyPack', {
            storePack: subscription,
            packDetails: packDetails,
        });
    }

    locale.subscribe(displayStoreSection);

    globals.resourceDetails = resourceDetails;
    globals.formatTimeFriendly = formatTimeFriendly;
    $: packRarity = getPackRarity(rarity);

    // console.log(subscription);

    function getBonus() {
        if (storeSection === 'subscriptions') {
            if (subscription.showBonus) {
                return Math.floor((goldWorth + goldAmount * days + goldBonus) / (goldAmount * days + goldBonus)) * 100;
            } else {
                if (subscription.discountValue !== undefined) {
                    return `-${subscription.discountValue}`;
                }
            }
        }
    }

    let subscriptionTotalItems = items !== [] ? items.map(item => {
        return {
            ...item,
            amount: item.amount * days
        }
    }) : [];
</script>

{#if $gameConfig}
    {#await $gameConfig.gameItems then gameItems}
        {#if packDetails}
            <div class="pack {storeSection === 'subscriptions' ? packRarity: ''} {storeSection} {subscription.collected ? '': 'active'} promos animate__animated {animate ? 'animate__rubberBand' : ''}">
                {#if packDetails.showOneTimeOnly}
                    <Ribbon>{$_('OneTimeOnlyPromoText')}</Ribbon>
                {/if}
                {#if storeSection === 'subscriptions' && subscription.showBonus}
                    <Starburst>
                        {getBonus()}%
                    </Starburst>
                {/if}
                <div class="content">
                    {#if storeSection === "resources"}
                        <div class="header">
                            <span class="shadow"></span>
                            <picture>
                                <source srcset="{amazon_pack_image_url}/{subscription.bundleName}/image.webp"
                                        type="image/webp">
                                <source srcset="{amazon_pack_image_url}/{subscription.bundleName}/image.jpg"
                                        type="image/jpeg">
                                <img
                                        class="pack-image"
                                        src="{amazon_pack_image_url}/{subscription.bundleName}/image.jpg"
                                        alt={$_(packDetails.title)}
                                />
                            </picture>
                        </div>
                    {/if}

                    <div class="sub-header">
                        <h3>{$_(packDetails.subTitle)}</h3>

                        {#if storeSection === "resources" && goldAmount}
                            <div class="price">
                                <span class="gold">{$_("PacksReceiveGold")} {$number(goldAmount)} {$_("Gold")}</span>
                            </div>
                        {/if}
                        <p>
                            {@html $_(packDetails.description)}
                        </p>
                    </div>

                    {#if storeSection === "subscriptions"}
                        <ItemsListing globals={globals} goldAmount={goldAmount} items={subscriptionTotalItems}
                                      storePack={{}}/>
                    {/if}
                </div>

                {#if storeSection === 'resources'}
                    <div class="button-background"></div>
                {/if}

                {#if isPurchased}
                    {#if subscription.collected}
                        <span class="timer">{$_('SubscriptionPackCollectPopupInfo')}</span>
                        <Button class="claim-button green" primary disabled>
                            {$_('Collected')}
                        </Button>
                    {:else}
                        {#if storeSection === 'subscriptions' && subscription.finishAt}
                            <Countdown endDate={subscription.finishAt}>
                                {$_('IncrementalPacksTimeLeft')}
                            </Countdown>
                        {/if}

                        {#if storeSection === 'subscriptions' && subscription.endAt}
                            <Countdown endDate={subscription.endAt}>
                                {$_('IncrementalPacksTimeLeft')}
                            </Countdown>
                        {/if}

                        <Button class="claim-button green" primary loading={processing}
                                on:click={collectDailySubscription}>
                            {$_('Collect')}
                        </Button>

                        {#if storeSection === 'resources' && subscription.finishAt}
                            <Countdown endDate={subscription.finishAt}>
                                {$_('IncrementalPacksTimeLeft')}
                            </Countdown>
                        {/if}
                    {/if}
                {:else}
                    {#if subscription.finishAt}
                        <Countdown endDate={subscription.finishAt}>
                            {$_('IncrementalPacksTimeLeft')}
                        </Countdown>
                    {:else}
                        {#if storeSection === 'subscriptions' }
                            {#if subscription.endAt}
                                <Countdown endDate={subscription.endAt}>
                                    {$_('IncrementalPacksTimeLeft')}
                                </Countdown>
                            {:else}
                                <span class="timer"></span>
                            {/if}
                        {/if}
                    {/if}
                    <Button primary class="buy-button orange" on:click={buyPack}>
                        <!--                    <img class="snow3" src="images/snow2.png" alt=""/>-->
                        <img alt="Discount" class="lucky-draw-discount"
                             src="/images/lucky_draw/badge/red_discount_10.png"/>
                        <s>
                            {$number(
                                subscription.webshopPrices[$selectedCurrency].fullPrice / 100,
                                {
                                    style: 'currency',
                                    currency: $selectedCurrency,
                                },
                            )}
                        </s>
                        {$number(subscription.webshopPrices[$selectedCurrency].price / 100, {
                            style: 'currency',
                            currency: $selectedCurrency,
                        })}
                    </Button>
                    {#if storeSection === 'resources' || storeSection === 'subscriptions'}
                        <span class="timer validFor">{$_('NewShopValidFor')} {days} {$_('Days')}</span>
                    {/if}
                {/if}
            </div>
        {/if}
    {/await}
{/if}

<style lang="scss">
  @import '../styles/vars.scss';

  :global {

    .pack {

      &.subscriptions {
        height: $store-pack-height-subscriptions;

        .starburst {
          right: -16px;
          top: -28px;
        }

        .sub-header {
          margin-top: -5px;
          height: 100px;
          top: 0;
          overflow: visible;
          text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
        }

        h3 {
          font-family: var(--font-family-sans-condensed);
          font-size: 17px;
          line-height: 24px;
          font-weight: 700;
          margin: 0;
          color: #feffd6;
        }

        p {
          font-size: 12px;
          font-weight: 400;
          margin: 0;
          padding: 0;
          line-height: 14px;
        }

        .items {
          margin-top: 75px;
        }

        .claim-button.green {
          height: 52px;
        }

        .timer {
          height: 12px;
        }

        .validFor {
          position: relative;
          bottom: 2px;
        }
      }

      &.resources {
        .content {
          height: 220px;
        }

        .sub-header {
          margin-top: -30px;
          height: 220px;
          padding-bottom: 105px;

          h3 {
            margin-top: 5px;
            font-size: 16px;
            line-height: 20px;
          }

          p {
            font-size: 12px;
            line-height: 13px;
            margin-bottom: 5px;
          }

          .gold {
            font-size: 14px;
            text-shadow: 1px 1px 0 rgb(0 0 0 / 40%);
          }
        }

        .timer {
          text-align: center !important;
          display: inline-block;
          font-size: 12px;
          line-height: 12px;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.7);
          text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
          transition: opacity 0.4s;
          margin-bottom: -12px;
          margin-top: 12px;
          z-index: 15;
        }

        .claim-button.green {
          height: 52px;
        }
      }

    }

    .button-background {
      box-shadow: none;
      background: rgba(0, 0, 0, 0.231372549);
      position: absolute;
      z-index: 15;
      left: -10px;
      margin-top: 208px;
      height: 68px;
      right: -10px;
    }
  }
</style>