<script>
    import {_, locale} from 'svelte-i18n';
    import Item from './Item.svelte';

    export let item;
    export let gameItem;

    export let itemDetails = {};

    function setItemDetails() {
        itemDetails.amount = item.amount;
        itemDetails.rarity = `3`;
        itemDetails.claimed = item.claimed;

        switch (item.id) {
            case 2199:
                itemDetails.title = $_(`4thofjulyChatBubbleTitle`);
                itemDetails.description = $_(`4thofjulyChatBubbleDescription`);
                itemDetails.icon = `chat-bubble-4thofjuly`;
                break;
            default:
                itemDetails.title = $_(`DefaultChatBubbleTitle`);
                itemDetails.description = $_(`DefaultChatBubbleDescription`);
                itemDetails.icon = `chat-bubble`;
                break;
        }
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
