<script>
    import {_, locale, number} from 'svelte-i18n';
    import Item from './Item.svelte';
    import {selectedCurrency} from "../../stores.js";

    export let item;
    export let gameItem;

    export let itemDetails = {};

    function setItemDetails() {
        itemDetails.title = $_("GameTokenTitle", {
            values: {
                AMOUNT: $number(gameItem.price / 100, {
                    style: 'currency',
                    currency: $selectedCurrency,
                })
            }
        });
        itemDetails.description = $_("GameTokenDescription");
        itemDetails.amount = item.amount;
        itemDetails.icon = "packGameToken";
        itemDetails.rarity = gameItem.rarity;
        itemDetails.claimed = item.claimed;
    }

    locale.subscribe(setItemDetails);
</script>

<Item {itemDetails}/>
