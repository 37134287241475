<script>
  import { _, locale, dictionary } from 'svelte-i18n';
  import Item from './Item.svelte';
  import { gameConfig, user } from '../../stores';
  import { itemRarity, itemRarityLowercase } from '../../constants';

  export let item;
  export let gameItem;

  export let itemDetails = {};

  let commanderId;
  let commanderHasSide;

  const playerSide = () => {
    if ($user.user.side == 1) {
      return 'North';
    }

    return 'South';
  };

  function getCommanderName() {
    var commanders = $gameConfig.commanders;
    const result = Object.keys(commanders).filter((key) => {
      const object = commanders[key];
      const zeroTalent = Object.values(object.talents).reduce((acc, talent) => {
        acc = acc || talent.item === item.id;
        return acc;
      }, false);
      return zeroTalent;
    });

    commanderId = result[0];
    let commanderName = 'CommanderFullName' + commanderId + playerSide();

    // console.log($dictionary[$locale]);
    if ($dictionary[$locale][commanderName] !== undefined) {
      commanderHasSide = true;
      return $_('CommanderFullName' + commanderId + playerSide());
    }

    commanderHasSide = false;
    return $_('CommanderFullName' + commanderId);
  }

  function getDescription() {
    let descriptionText = $_('CommanderTalent_Description');

    if (descriptionText.includes('{RARITYF}')) {
      descriptionText = descriptionText.replace(
        '{RARITYF}',
        $_(itemRarity[gameItem.rarity] + '_F'),
      );
    } else if (descriptionText.includes('{RARITYM}')) {
      descriptionText = descriptionText.replace(
        '{RARITYM}',
        $_(itemRarity[gameItem.rarity] + '_M'),
      );
    } else if (descriptionText.includes('{RARITYN}')) {
      descriptionText = descriptionText.replace(
        '{RARITYN}',
        $_(itemRarity[gameItem.rarity] + '_N'),
      );
    }

    if (descriptionText.includes('{COMMANDER_NAME}')) {
      return descriptionText.replace('{COMMANDER_NAME}', getCommanderName());
    }

    return descriptionText;
  }

  function getCommanderTalentBackground() {
    var commanders = $gameConfig.commanders;
    // console.log(commanders);
    let commanderTalentBackground;
    if (commanderHasSide) {
      commanderTalentBackground =
        'commander_' +
        commanderId +
        '_' +
        playerSide() +
        '_' +
        itemRarityLowercase[gameItem.rarity] +
        '_medal.png';
    } else {
      commanderTalentBackground =
        'commander_' +
        commanderId +
        '_' +
        itemRarityLowercase[gameItem.rarity] +
        '_medal.png';
    }

    return commanderTalentBackground;
  }

  function setItemDetails() {
    itemDetails.title = $_('CommanderTalent' + gameItem.icon + '_Title', {
      values: { COMMANDER_NAME: getCommanderName() },
    });
    itemDetails.description = getDescription();
    itemDetails.amount = item.amount;
    itemDetails.icon = 'commanderTalent' + gameItem.icon;
    itemDetails.rarity = gameItem.rarity;
    itemDetails.claimed = item.claimed;
    itemDetails.commanderTalentBackground = getCommanderTalentBackground();
    // console.log(item);
  }

  locale.subscribe(setItemDetails);
</script>

<Item {itemDetails} />
