<script>
    import {number} from 'svelte-i18n';
    import '../../styles/atlases/GameItems.css';
    import '../../styles/atlases/HeroEquipment.css';
    import tippy from 'svelte-tippy';
    import 'tippy.js/dist/tippy.css'; // optional
    import 'tippy.js/animations/shift-away-subtle.css';
    import {itemRarityLowercase} from '../../constants';
    import greenCheckmark from '../../assets/gold_rush/green_checkmark.png?format=webp;jpg';

    export let itemDetails;

    let greenCheckmarkImage = greenCheckmark;

    const titleAttribute = {
        name: 'titleAttribute',
        defaultValue: true,
        fn() {
            return {
                onCreate(instance) {
                    if (!instance.props.titleAttribute) {
                        return;
                    }

                    const title = instance.reference.getAttribute('title');

                    if (title) {
                        instance.setContent(title);
                        instance.reference.removeAttribute('title');
                    }
                },
            };
        },
    };
</script>

<div
        alt={itemDetails.icon}
        class="item"
        title={itemDetails.description}
        use:tippy={{
    content: itemDetails.description,
    theme: 'shop',
    boundary: 'scrollingContainer',
    arrow: true,
    flip: false,
    placement: 'top-start',
    animation: 'shift-away-subtle',
    plugins: [titleAttribute],
    delay: 500,
  }}
>
    <div
            class="
    item-icon 
    {itemRarityLowercase[itemDetails.rarity]} 
    {itemDetails.icon}
    "
    >
        {#if itemDetails.commanderTalentBackground}
            <img src="images/medals/{itemDetails.commanderTalentBackground}" alt=""/>
        {/if}
    </div>

    {#if itemDetails.claimed}
        <picture class="greenCheckmark">
            <source srcset={greenCheckmarkImage[0]} type="image/webp">
            <source srcset={greenCheckmarkImage[1]} type="image/jpg">
            <img alt="Green Checkmark" src={greenCheckmarkImage[0]}>
        </picture>
    {/if}
    <p class="item-title">{itemDetails.title}</p>
    <strong class="item-amount">{$number(itemDetails.amount)}</strong>
</div>

<style lang="scss">
  .item {
    font-size: 0.75em;
    color: var(--color-darkGrey);
    height: 47px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: 3px 5px;
    display: flex;
    overflow: hidden;
    clear: both;
    position: relative;
    cursor: default;
    width: 100%;
    text-shadow: 0 1px 0px rgba(255, 255, 255, 0.7);
    justify-content: space-between;
    align-items: center;

    &:last-child {
      border: none;
    }

    &:hover {
      .greenCheckmark {
        transform: translateZ(0) scale(1.2);
      }
    }

    .greenCheckmark {
      position: absolute;
      bottom: 5px;
      right: 5px;
      z-index: 2;
      pointer-events: none;
      transition: transform 0.15s ease-in;
      backface-visibility: hidden;
      transform: translateZ(0) scale(1);
    }

    .item-icon {
      width: 40px;
      height: 40px;
      display: flex;
      max-width: 40px;
      max-height: 40px;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      position: relative;

      & > img {
        width: 100%;
        position: absolute;
      }

      &:hover {
        &::after {
          transform: translateZ(0) scale(1);
        }
      }

      &[class*='commanderTalent'] {
        transition: transform 0.15s ease-in;

        &:after {
          transform: scale(0.7) !important;
          margin-left: 0px;
        }

        &:hover {
          transform: scale(1.3);
        }
      }

      &:after {
        transition: transform 0.15s ease-in;
        backface-visibility: hidden;
        transform: translateZ(0) scale(0.8);
        image-rendering: crisp-edges;
      }

      &.common {
        background-image: url('/images/mini-item-holder-green.png');
        background-size: cover;
      }

      &.rare {
        background-image: url('/images/mini-item-holder-blue.png');
        background-size: cover;
      }

      &.epic {
        background-image: url('/images/mini-item-holder-purple.png');
        background-size: cover;
      }

      &.legendary {
        background-image: url('/images/mini-item-holder-orange.png');
        background-size: cover;
      }
    }

    strong {
      height: 100%;
      text-align: right;
      font-size: 1.2em;
      font-weight: bold;
      display: flex;
      align-items: center;
      flex-shrink: 0;
    }

    p {
      height: 100%;
      text-align: left;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      align-items: center;
      flex-grow: 1;
      justify-content: flex-start;
      line-height: 1em;
    }
  }
</style>
