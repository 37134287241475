<script>
  import { _, locale } from 'svelte-i18n';
  import Item from './Item.svelte';

  export let item;
  export let gameItem;

  export let itemDetails = {};

  function getTeleportDetails() {
    let teleportDetails = {};

    switch (gameItem.type) {
      case 'beginner':
        teleportDetails.title = 'BeginnerTeleport';
        teleportDetails.description = 'BeginnerTeleportDescription';
        teleportDetails.icon = 'teleport_beginner';
        break;
      case 'advanced':
        teleportDetails.title = 'AdvancedTeleport';
        teleportDetails.description = 'AdvancedTeleportDescription';
        teleportDetails.icon = 'teleport_advanced';
        break;
      case 'random':
        teleportDetails.title = 'RandomTeleportTitle';
        teleportDetails.description = 'RandomTeleportDescription';
        teleportDetails.icon = 'teleport_random';
        break;
      case 'super':
        teleportDetails.title = 'SuperWonderTeleport';
        teleportDetails.description = 'SuperWonderTeleportDescription';
        teleportDetails.icon = 'teleport_super';
        break;
    }

    return teleportDetails;
  }
  function setItemDetails() {
    var teleportDetails = getTeleportDetails();

    itemDetails.title = $_(teleportDetails.title);
    itemDetails.description = $_(teleportDetails.description);
    itemDetails.amount = item.amount;
    itemDetails.icon = teleportDetails.icon;
    itemDetails.rarity = gameItem.rarity;
    itemDetails.claimed = item.claimed;
  }

  locale.subscribe(setItemDetails);
</script>

<Item {itemDetails} />
