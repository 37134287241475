<script>
  import { _, locale } from 'svelte-i18n';
  import Item from './Item.svelte';

  export let storePack;

  export let itemDetails = {};

  const allianceGifts = {
    1: {
      name: 'IronPack',
      icon: 'iron_gift',
      rarity: 1,
    },
    2: {
      name: 'BronzePack',
      icon: 'bronze_gift',
      rarity: 1,
    },
    3: {
      name: 'SilverPack',
      icon: 'silver_gift',
      rarity: 2,
    },
    4: {
      name: 'GoldPack',
      icon: 'gold_gift',
      rarity: 3,
    },
    5: {
      name: 'PlatinumPack',
      icon: 'platinum_gift',
      rarity: 4,
    },
    6: {
      name: 'StarterPack',
      icon: 'starter_pack_gift',
      rarity: 2,
    },
    7: {
      name: 'SpecialPack1',
      icon: 'special_pack_1_gift',
      rarity: 1,
    },
    8: {
      name: 'SpecialPack2',
      icon: 'special_pack_2_gift',
      rarity: 2,
    },
    9: {
      name: 'SpecialPack3Gift',
      icon: 'special_pack_3_gift',
      rarity: 3,
    },
    10: {
      name: 'EasterGift',
      icon: 'gift_easter',
      rarity: 2,
    },
    11: {
      name: 'SpecialPack5',
      icon: 'special_pack_2_gift',
      rarity: 4,
    },
    12: {
      name: 'SpecialPack6',
      icon: 'special_pack_2_gift',
      rarity: 4,
    },
    13: {
      name: 'SpecialPack7',
      icon: 'special_pack_2_gift',
      rarity: 4,
    },
    14: {
      name: 'SpecialPack8',
      icon: 'special_pack_2_gift',
      rarity: 4,
    },
    15: {
      name: 'SpecialPack9',
      icon: 'alliance_gift_type_15',
      rarity: 4,
    },
    16: {
      name: 'SpecialPack10',
      icon: 'alliance_gift_type_16',
      rarity: 4,
    },
    100: {
      name: 'GoldGiftAllianceEventPrize',
      icon: 'gold_coins',
      rarity: 2,
    },
  };

  function setItemDetails() {
    let allianceGift = allianceGifts[storePack.giftType];

    itemDetails.title = $_(allianceGift.name);
    itemDetails.description = $_('AllianceGiftDescription');
    itemDetails.amount = 1;
    itemDetails.icon = allianceGift.icon;
    itemDetails.rarity = allianceGift.rarity;
  }

  locale.subscribe(setItemDetails);
</script>

<Item {itemDetails} />
