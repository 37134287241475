<script>
  import { _, locale } from 'svelte-i18n';
  import Item from './Item.svelte';
  import { capitalizeFirstLetter } from '../../utils';

  export let item;
  export let gameItem;

  export let itemDetails = {};

  function setItemDetails() {
    itemDetails.title = $_(
      'Refresh' + capitalizeFirstLetter(gameItem.type) + 'MissionsItemTitle',
    );
    itemDetails.description = $_(
      'Refresh' +
        capitalizeFirstLetter(gameItem.type) +
        'MissionsItemDescription',
    );
    itemDetails.amount = item.amount;
    itemDetails.icon = 'refresh_' + capitalizeFirstLetter(gameItem.type);
    itemDetails.rarity = gameItem.rarity;
    itemDetails.claimed = item.claimed;
  }

  locale.subscribe(setItemDetails);
</script>

<Item {itemDetails} />
