<script>
  import { _, number, locale } from 'svelte-i18n';
  import Item from './Item.svelte';

  export let item;
  export let gameItem;
  export let globals;

  export let itemDetails = {};

  function getSpriteName() {
    if (gameItem.category == 'vipPoints') {
      if (gameItem.amount <= 1000) {
        return 'vipPoints1';
      } else if (gameItem.amount <= 50000) {
        return 'vipPoints2';
      } else {
        return 'vipPoints3';
      }
    } else {
      if (gameItem.seconds <= 86400) {
        return 'vipTime1';
      } else if (gameItem.seconds <= 604800) {
        return 'vipTime2';
      } else {
        return 'vipTime3';
      }
    }
  }

  function setItemDetails() {
    if (gameItem.category == 'vipPoints') {
      itemDetails.title = $_(gameItem.category + 'ItemTitle', {
        values: { POINTS: $number(gameItem.amount) },
      });
      itemDetails.description = $_(gameItem.category + 'ItemDescription', {
        values: { POINTS: $number(gameItem.amount) },
      });
    } else {
      itemDetails.title = $_(gameItem.category + 'ItemTitle', {
        values: { TIME: globals.formatTimeFriendly(gameItem.seconds) },
      });
      itemDetails.description = $_(gameItem.category + 'ItemDescription', {
        values: { TIME: globals.formatTimeFriendly(gameItem.seconds) },
      });
    }

    itemDetails.amount = item.amount;
    itemDetails.icon = getSpriteName();
    itemDetails.rarity = gameItem.rarity;
    itemDetails.claimed = item.claimed;
  }

  locale.subscribe(setItemDetails);
</script>

<Item {itemDetails} />
