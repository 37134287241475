<script>
  import { _, locale, number } from 'svelte-i18n';
  import Item from './Item.svelte';

  export let item;
  export let gameItem;

  export let itemDetails = {};

  function getTroopDetails() {
    let troopDetails = {};

    switch (gameItem.type) {
      case 1: {
        troopDetails.name = $_('InfantryTroopsTier' + gameItem.tier);
        troopDetails.typeName = $_('InfantryTroops');
        break;
      }
      case 2: {
        troopDetails.name = $_('RangedTroopsTier' + gameItem.tier);
        troopDetails.typeName = $_('RangedTroops');
        break;
      }
      case 3: {
        troopDetails.name = $_('CavalryTroopsTier' + gameItem.tier);
        troopDetails.typeName = $_('CavalryTroops');
        break;
      }
      case 4: {
        troopDetails.name = $_('SiegeTroopsTier' + gameItem.tier);
        troopDetails.typeName = $_('SiegeTroops');
        break;
      }
    }

    return troopDetails;
  }

  function setItemDetails() {
    itemDetails.title = $_(
      'troopsTier' + gameItem.tier + 'Type' + gameItem.type + 'ItemTitle',
    );
    itemDetails.description = $_(
      'troopsTier' + gameItem.tier + 'Type' + gameItem.type + 'ItemDescription',
      {
        values: { AMOUNT: $number(item.amount) },
      },
    );
    itemDetails.icon = 'troop_tier_' + gameItem.tier + '_' + gameItem.type;
    itemDetails.amount = item.amount;
    itemDetails.rarity = gameItem.rarity;
    itemDetails.claimed = item.claimed;
  }

  locale.subscribe(setItemDetails);
</script>

<Item {itemDetails} />
